import * as React from 'react'

import { styled } from '@thg-commerce/gravity-elements/theme'
import { ProductBlockPriceDisplayStyle } from '@thg-commerce/gravity-patterns/ProductBlock/theme'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import {
  Orientation,
  spacing,
  Text,
  TextStyling,
} from '@thg-commerce/gravity-theme'

export interface ProductPriceProps {
  price: {
    defaultPrice: string
    minPrice?: string | null
    maxPrice?: string | null
  }
  rrp?: {
    defaultPrice?: string | null
    minPrice?: string | null
    maxPrice?: string | null
  } | null
  freeText?: string
  rrpText?: string
  discountAmount?: string
  vipPrice?: string
  hasFromPrice?: boolean
  fromText?: string
  className?: string
  saveUpToText?: string
  maxSavings?: string
  rrpRange?: string
  priceRange?: string
  showRrpInline?: boolean
  hideSearchPriceInHeader?: boolean
  savings?: {
    show?: boolean
    textStyle?: TextStyling
  }
  i18nText?: {
    saveLabel?: string
    offLabel?: string
    saveTextLabel?: string
    savingTextLabel?: string
  }
  savingsPercentage?: {
    show?: boolean
    showPercentageOnly?: boolean
  }
  enableSavePrice?: boolean
  fbtOrientation?: Orientation
}

const Container = styled.div<{
  displayStyle?: string
  orientation?: Orientation
  fbtOrientation?: Orientation
}>`
  display: flex;
  align-items: ${(props) =>
    props.theme.elements.productPrice.align ||
    (props.orientation === Orientation.HORIZONTAL ? 'flex-end' : 'flex-start')};
  flex-direction: ${(props) =>
    (props.fbtOrientation &&
      (props.fbtOrientation === Orientation.HORIZONTAL ? 'row' : 'column')) ||
    props.theme.elements.productPrice.direction ||
    (props.orientation === Orientation.HORIZONTAL ? 'row' : 'column')};
  ${(props) =>
    Text(
      props.theme.elements.productPrice.priceWithRRP.entry,
      props.theme.elements.productPrice.priceWithRRP.style,
    )};
  ${(props) =>
    props.orientation === Orientation.HORIZONTAL && `gap: ${spacing(1)};`}
  ${(props) =>
    props.fbtOrientation === Orientation.HORIZONTAL && `gap: ${spacing(1)};`}
`

const Price = styled.div<{
  hasRrp: boolean
}>`
  ${(props) =>
    Text(
      props.hasRrp
        ? props.theme.elements.productPrice.priceWithRRP.entry
        : props.theme.elements.productPrice.price.entry,
      props.hasRrp
        ? props.theme.elements.productPrice.priceWithRRP.style
        : props.theme.elements.productPrice.price.style,
    )}
  color: ${(props) =>
    props.hasRrp
      ? props.theme.elements.productPrice.priceWithRRP.textColor
      : props.theme.elements.productPrice.price.textColor};
`

const RrpContainer = styled.div<{ showInline?: boolean }>`
  ${Text('xsmall', 'default')};
  display: ${(props) => (props.showInline ? 'inline' : 'flex')};
  align-items: center;
  ${(props) => props.showInline && `padding-left: ${spacing(1)};`}
`

const RrpPrice = styled.div<{ showInline?: boolean }>`
  ${(props) =>
    Text(
      props.theme.elements.productPrice.rrp.entry,
      props.theme.elements.productPrice.rrp.style,
    )};
  color: ${(props) => props.theme.elements.productPrice.rrp.textColor};
  text-decoration: ${(props) =>
    props.theme.elements.productPrice.rrp.textDecoration};
  ${(props) => props.showInline && `display: inline;`}
`

const RrpText = styled.span`
  margin-right: ${spacing(0.5)};
  ${(props) =>
    Text(
      props.theme.elements.productPrice.rrp.entry,
      props.theme.elements.productPrice.rrp.style,
    )};
  color: ${(props) => props.theme.elements.productPrice.rrp.textColor};
`

const SaveUpToContainer = styled.div`
  ${(props) =>
    Text(
      props.theme.elements.productPrice.saveUpTo.entry,
      props.theme.elements.productPrice.saveUpTo.style,
    )};
  color: ${(props) => props.theme.elements.productPrice.saveUpTo.textColor};
  text-decoration: ${(props) =>
    props.theme.elements.productPrice.saveUpTo.textDecoration};
`

const createPriceValue = (config: {
  displayStyle: ProductBlockPriceDisplayStyle
  defaultPrice?: string | null
  range?: string | null
}) => {
  if (!config.range) {
    return config.defaultPrice || null
  }

  if (
    config.displayStyle === ProductBlockPriceDisplayStyle.PRICE_RANGE &&
    config.range
  ) {
    return config.range
  }

  return config.defaultPrice || null
}

const getRrpContainer = (
  shouldShowRRPText: boolean,
  shouldShowRRPValue: boolean,
  rrpValue: string | null | undefined,
  rrpTextFromProp: string | undefined,
  rrpTextFromTheme: string | undefined,
  shouldShowRRP: boolean = false,
  showRrpInline: boolean = false,
) =>
  rrpValue &&
  shouldShowRRP && (
    <RrpContainer showInline={showRrpInline}>
      {shouldShowRRPText && (
        <RrpText>{rrpTextFromProp ?? rrpTextFromTheme}</RrpText>
      )}
      {shouldShowRRPValue && (
        <RrpPrice data-testid="rrp-price" showInline={showRrpInline}>
          {rrpValue}
        </RrpPrice>
      )}
    </RrpContainer>
  )

const extractNumericValue = (value: string): number => {
  const numericString = value.replace(/[^\d.,]+/g, '')
  const parsedValue = parseFloat(numericString)

  return isNaN(parsedValue) ? 0 : parsedValue
}

const getCurrencySymbol = (price: any) => {
  const currencySymbol = price.defaultPrice.replace(/[0-9\.,]+/g, '')
  return {
    currencySymbol,
    indexOfCurrency: price.defaultPrice.indexOf(currencySymbol),
  }
}

const SavePrice = ({
  enableSavePrice,
  save,
}: {
  enableSavePrice?: boolean
  save?: { value: string } | null
}) => {
  if (!enableSavePrice || !save?.value) {
    return null
  }

  return (
    <SaveUpToContainer data-testid="savings-price">
      {save.value}
    </SaveUpToContainer>
  )
}

export const ProductPrice = ({
  freeText,
  price,
  rrp,
  rrpText,
  hasFromPrice,
  fromText,
  className,
  rrpRange,
  priceRange,
  showRrpInline,
  enableSavePrice,
  i18nText,
  hideSearchPriceInHeader,
  fbtOrientation,
}: ProductPriceProps) => {
  const theme = useTheme()

  const save = React.useMemo(() => {
    if (rrp?.defaultPrice) {
      let priceAsNumber
      let rrpAsNumber
      let saveText

      const { currencySymbol, indexOfCurrency } = getCurrencySymbol(price)

      const isSamePrice =
        price.defaultPrice === price.maxPrice ||
        !price.maxPrice ||
        !rrp.maxPrice ||
        price.maxPrice === rrp.maxPrice

      if (isSamePrice) {
        priceAsNumber = extractNumericValue(price.defaultPrice)
        rrpAsNumber = extractNumericValue(rrp.defaultPrice)
        saveText = i18nText?.saveTextLabel
      } else {
        priceAsNumber = price.maxPrice && extractNumericValue(price.maxPrice)
        rrpAsNumber = rrp.maxPrice && extractNumericValue(rrp.maxPrice)
        saveText = i18nText?.savingTextLabel
      }
      const savings = (rrpAsNumber - priceAsNumber).toFixed(2)
      if (!savings) {
        return null
      }

      return {
        value:
          indexOfCurrency !== 0
            ? `${saveText} ${savings}${currencySymbol}`
            : `${saveText} ${currencySymbol}${savings}`,
      }
    }
    return null
  }, [price, rrp])

  if (freeText) {
    return <Container className={className}>{freeText}</Container>
  }

  const {
    displayStyle,
    orientation,
    displayRRPText,
    displayRRPValue,
    displayFromText,
  } = theme.patterns.productBlock.price

  const priceValue = createPriceValue({
    ...price,
    displayStyle,
    range: priceRange,
  })

  const rrpValue = createPriceValue({
    ...rrp,
    displayStyle,
    range: rrpRange,
  })

  const shouldShowRRPText = displayRRPText ?? true

  const shouldShowRRPValue = displayRRPValue ?? true

  const shouldShowFromText = displayFromText ?? false

  const fromPriceText = shouldShowFromText ? fromText : ''

  const shouldShowPrice = priceValue && !hideSearchPriceInHeader
  return (
    <Container
      className={className}
      displayStyle={displayStyle}
      orientation={orientation}
      fbtOrientation={fbtOrientation}
    >
      {!shouldShowFromText && hasFromPrice && <span>{fromText}</span>}{' '}
      {getRrpContainer(
        shouldShowRRPText,
        shouldShowRRPValue,
        rrpValue,
        rrpText,
        theme.patterns.productBlock.price.rrpText,
        !showRrpInline,
        showRrpInline,
      )}
      {shouldShowPrice && (
        <Price hasRrp={!!rrpValue} data-testid="sale-price">
          {fromPriceText} {priceValue}
          {getRrpContainer(
            shouldShowRRPText,
            shouldShowRRPValue,
            rrpValue,
            rrpText,
            theme.patterns.productBlock.price.rrpText,
            showRrpInline,
            showRrpInline,
          )}
        </Price>
      )}
      <SavePrice enableSavePrice={enableSavePrice} save={save} />
    </Container>
  )
}
